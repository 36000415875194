import React from 'react';
import './Landing.style.scss';
import LineGirl from '../../Assets/Images/girl.png';
import behance from '../../Assets/Images/behance.png';
import dribbble from '../../Assets/Images/dribbble.png';
import figma from '../../Assets/Images/icons.png';
import linkedin from '../../Assets/Images/linkedin.png';
import instagram from '../../Assets/Images/instagram.png';

const LINKS = {
  FACEBOOK: '',
  INSTAGRAM: 'https://instagram.com/she.dessigns?igshid=YmMyMTA2M2Y=',
  LINKEDIN: 'https://www.linkedin.com/in/prelisa-dahal-279737123/',
  FIGMA: 'https://www.figma.com/@prelisa',
  BEHANCE: 'https://www.behance.net/prelisadahal',
  DRIBBBLE: 'https://dribbble.com/PrelisaDahal',
};

function Landing() {
  return (
    <div className='landing-container'>
      <div className='lineGirl-container'>
        <img src={LineGirl} alt='line girl' />
      </div>
      <div className='details-container'>
        <header>
          <div className='menuItem selected'>Home</div>
          <div className='menuItem'>Case Studies</div>
          <div className='menuItem'>UI Challenge</div>
          <div className='menuItem'>Experiences</div>
          <div className='menuItem'>Resume</div>
        </header>
        <div className='personalDetails-container'>
          <h1>Prelisa Dahal</h1>
          <div className='job'>Product Designer @ Leapfrog</div>
          <div className='description'>
            <p>
              A designer with a passion for creating visually stunning and impactful designs that solves problems. From
              web design to branding and everything in between, I specialize in bringing unique and creative ideas to
              life.
            </p>
            <p>Let's work together to create something amazing.</p>
          </div>
        </div>
        <div className='socials-container'>
          <img
            src={linkedin}
            onClick={() => {
              window.open(LINKS['LINKEDIN'], '_blank');
            }}
            alt=''
          />
          <img
            src={dribbble}
            alt=''
            onClick={() => {
              window.open(LINKS['DRIBBBLE'], '_blank');
            }}
          />
          <img
            src={behance}
            alt=''
            onClick={() => {
              window.open(LINKS['BEHANCE'], '_blank');
            }}
          />
          <img
            src={instagram}
            alt=''
            onClick={() => {
              window.open(LINKS['INSTAGRAM'], '_blank');
            }}
          />
          <img
            src={figma}
            alt=''
            onClick={() => {
              window.open(LINKS['FIGMA'], '_blank');
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Landing;
