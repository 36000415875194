import React from 'react';
import Landing from './Pages/Landing/Landing.page';
import './App.scss';
function App() {
  return (
    <div className='main-container'>
      <Landing />
    </div>
  );
}

export default App;
